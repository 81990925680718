import { CameraControls, OrbitControls } from '@react-three/drei'
import { CamData, Props } from './Types'
import { useEffect, useRef } from 'react'

const CAM: CamData = {
  orbit: { pos: [0, .85, 0], target: [0, .85, 0] },
  snapshot:  { pos: [0, 1.5, 1.4], target: [0, 1.45, 0] },
  fullBody: { pos: [0, 1.1, 2.8], target: [0, 0.9, 0] },
  skinColor: { pos: [0, 0.85, 3], target: [0, 0.85, 0] },
  expression: { pos: [-0.3, 1.35, 1.8], target: [0, 1.45, 0] },
  head: { pos: [0, 1.35, 2], target: [0, 1.45, 0] },
  eyewear: { pos: [0.3, 1.35, 1.8], target: [0, 1.45, 0] },
  torsoInner: { pos: [0, 0.75, 1.8], target: [0, 0.85, 0] },
  torsoOuter: { pos: [0, 0.75, 1.8], target: [0, 0.85, 0] },
  legs: { pos: [-0.2, 0.4, 1.5], target: [0, 0.4, 0] },
  feet: { pos: [-0.5, 0.3, 1], target: [0, 0.2, 0] },
  hands: { pos: [0, 0.85, 3], target: [0, 0.85, 0] },
  background: { pos: [0, 1.1, 5.8], target: [0, 1.1, 0] },
}

const Camera3Component: React.FC<Props> = ({ lookAt, editor, preview }: Props) => {
  const cameraControlRef = useRef<CameraControls | null>(null)

  useEffect(() => {
    if (!cameraControlRef.current) return
    const cameraControl = cameraControlRef.current as CameraControls
    cameraControl.setLookAt(
      CAM[lookAt].pos[0],
      CAM[lookAt].pos[1],
      CAM[lookAt].pos[2],
      CAM[lookAt].target[0],
      CAM[lookAt].target[1],
      CAM[lookAt].target[2],
      true
    )
    !editor && cameraControl.disconnect()
  }, [lookAt, cameraControlRef.current])

  if (preview && lookAt === 'orbit') return <OrbitControls position={[0, .85, 0]} target={[0, .85, 0]} maxDistance={4} />
  return <CameraControls ref={cameraControlRef} minDistance={1} maxDistance={4} smoothTime={0.15} />
}

export default Camera3Component
