import { PayloadAction } from '@reduxjs/toolkit'

import { AvatarState } from '../Types'
import { UpdateOutfitPayload } from 'store/Wearables/Types'

const updateOutfit = (state: AvatarState, action: PayloadAction<UpdateOutfitPayload>) => {
    const { slot, wearableId, file } = action.payload
    return {
        ...state,
        staged: {
            ...state.staged,
            outfit: {
                ...state.staged.outfit,
                [slot]: {
                    wearableId,
                    file,
                },
            },
        },
    }
}

export default updateOutfit
