import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, update } from 'firebase/database'

import Routes from 'api/Firebase/Routes'

import { exportGlb } from 'utils/exportGlb'
import { handleAssetUpload } from 'utils/handleAssetUpload'

import { SaveGlbParams } from '../Types'

const saveGlbByUserIdThunk = createAsyncThunk(
    'avatar/saveGlbByUserIdThunk',
    async ({ userId, accessToken, scene, firebaseDb, lambdaUrl }: SaveGlbParams) => {
        const DATABASE = ref(firebaseDb)

        if (!scene) {
            throw new Error('Three scene is null')
        }

        const exportGroup = scene.getObjectByName('avatar')

        // Export the avatar with the hand item
        const glbBlobWithHandWearable = await exportGlb(exportGroup)
        const characterModelWithHandWearable = await handleAssetUpload(
            'putGlbUrl',
            'model/gltf-binary',
            glbBlobWithHandWearable,
            accessToken,
            lambdaUrl
        )

        scene.traverse(object => {
            if (object.name === 'hands') {
                object.visible = false
            }
        })

        // Export the avatar without the hand item
        const glbBlobWithoutHandWearable = await exportGlb(exportGroup)
        const characterModel = await handleAssetUpload(
            'putGlbUrl',
            'model/gltf-binary',
            glbBlobWithoutHandWearable,
            accessToken,
          lambdaUrl
        )

        scene.traverse(object => {
            if (object.name === 'hands') {
                object.visible = true
            }
        })

        // Update Firebase with the saved keys
        const updateObj: any = {
            [Routes.userAvatars(userId, 'characterModel')]: characterModel,
            [Routes.userAvatars(userId, 'characterModelWithHandWearable')]: characterModelWithHandWearable,
        }

        await update(DATABASE, updateObj)

        return { characterModel, characterModelWithHandWearable }
    }
)

export default saveGlbByUserIdThunk
