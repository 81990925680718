import { Database } from 'firebase/database'

// describes the structure of the avatar data in Firebase
export const RARITIES = ['common', 'rare', 'limited'] as const
export type RarityTuple = typeof RARITIES
export type Rarity = RarityTuple[number]

export const ACCESS_LEVELS = ['owned', 'visible', 'hidden'] as const
export type AccessLevelTuple = typeof ACCESS_LEVELS
export type AccessLevel = AccessLevelTuple[number]

export const OUTFIT_SLOTS = ['eyewear', 'feet', 'hands', 'head', 'legs', 'torsoInner', 'torsoOuter'] as const
export type OutfitSlotTuple = typeof OUTFIT_SLOTS
export type OutfitSlot = OutfitSlotTuple[number]

export const EXPRESSION_SLOTS = ['eyes', 'mouth'] as const
export type ExpressionSlotTuple = typeof EXPRESSION_SLOTS
export type ExpressionSlot = ExpressionSlotTuple[number]

export interface Wearable {
    file: url
    name: string
    rarity: Rarity
    slot: OutfitSlot
    thumbnail: url
    toibucks?: number
    handShape?: string
}

export interface Background {
    backgroundId: string
    name: string
    thumbnail: url
    file: url
}

export interface WearablesById {
    [wearableId: uid]: Wearable
}

export interface Expression {
    file: url
    name: string
    slot: ExpressionSlot
    thumbnail: url
}

export type UserAvatar = {
    skinColor: hex
    outfit: UserAvatarOutfit
    thumbnail: url
    characterModel: url
    characterModelWithHandWearable: url
    background: UserAvatarBackground
} & UserAvatarExpressionSlots

export interface UserAvatarExpression {
    expressionId: uid
    file: url
    position: Position
    scale: number
}

type UserAvatarExpressionSlots = {
    [key in ExpressionSlot]: UserAvatarExpression
}

export interface UserAvatarWearable {
    wearableId: uid
    file: url
    handShape?: string
}

type UserAvatarOutfitSlots = {
    [key in OutfitSlot]: UserAvatarWearable
}

export type UserAvatarOutfit = {
    userOutfitId: uid
} & UserAvatarOutfitSlots

export interface Outfit {
    eyewearId: uid
    feetId: uid
    handsId: uid
    headId: uid
    legsId: uid
    torsoInnerId: uid
    torsoOuterId: uid
}

export type UserWearable = {
    [key in OutfitSlot]: {
        [wearableId: string]: AccessLevel
    }
}

export interface UserAvatarBackground {
    backgroundId: string
    file: url
}

export interface AuthParams {
    userId: uid
    accessToken: string
}

export type FirebaseAccessParams = {
    firebaseDb: Database
} & AuthParams
