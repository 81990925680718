import { HexColorPicker } from 'react-colorful'

import { Container } from './Styles'
import { Props } from './Types'

const ColorPicker: React.FC<Props> = ({ color, onColorChange }: Props) => {
    return (
        <Container>
            <HexColorPicker color={color} onChange={onColorChange} />
        </Container>
    )
}

export default ColorPicker
