import { OUTFIT_SLOTS } from 'api/Firebase/Types'
import { FirebaseOptions } from 'firebase/app'

export const OPTION_CATEGORIES = ['skinColor', 'expression', 'background', ...OUTFIT_SLOTS, 'snapshot', 'fullBody'] as const
export type OptionCategoryTuple = typeof OPTION_CATEGORIES
export type OptionCategory = OptionCategoryTuple[number]

export type ComponentProps = {
    userId: string
    accessToken: string
    firebaseConfig: FirebaseOptions
    editor: boolean
    showStats?: boolean
    animName?: string
    animFilename?: string
    backgroundColor?: string
    onSaveCallback?: () => void
    previewHandShape?: any
    storageBaseUrl: string
    lambdaUrl: string
    assetUrl: string
}

export type Props = Overwrite<React.HTMLAttributes<HTMLDivElement>, ComponentProps>
