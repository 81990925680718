import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { LinearToneMapping } from 'three'
import { Perf } from 'r3f-perf'

import Avatar from './Avatar'
import Background from './Background'
import Camera from './Camera'

import { LoadingBar } from './Styles'

import { InternalProps, LoadingItems, Props, ThreeRef } from './Types'

const AvatarCanvas3Component = ({ threeRef, backgroundColor, editor, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const bgColorCode = backgroundColor ? backgroundColor : 'grey'

  return (
    <>
      <Canvas
        gl={{ preserveDrawingBuffer: true, toneMapping: LinearToneMapping }}
        shadows={'soft'}
        camera={{ fov: 50 }}
        color={bgColorCode}
      >
        <color attach='background' args={[bgColorCode]} />
        <CanvasInternal ref={threeRef} onLoading={setIsLoading} {...props} />
      </Canvas>
      {
        !editor && <LoadingBar isLoading={isLoading} />
      }
    </>
  )
}

const CanvasInternal = forwardRef<ThreeRef | null, InternalProps>(
  ({ avatar, background, camera, onLoading, editor, showStats = false }: InternalProps, ref: Ref<ThreeRef>) => {
    const three = useThree()

    useImperativeHandle(
      ref,
      (): ThreeRef => ({
        getCanvas: () => three.gl.domElement,
        getScene: () => three.scene,
      })
    )

    const [loadingItems, setLoadingItems] = useState<LoadingItems>({})

    const updateLoadingItems = (newloadingItems: LoadingItems) => {
      setLoadingItems((prev: LoadingItems) => {
        return { ...prev, ...newloadingItems }
      })
    }

    useEffect(() => {
      const index = Object.values(loadingItems).findIndex((item: boolean) => item === true)
      onLoading(index !== -1)
    }, [loadingItems, onLoading])

    return (
      <>
        {showStats && <Perf showGraph={false} />}
        <Camera {...camera} />
        <Background {...background} editor onLoading={updateLoadingItems} />
        <Avatar {...avatar} onLoading={updateLoadingItems} />
      </>
    )
  }
)

export default AvatarCanvas3Component
