import React, { useEffect } from 'react'
import isEqual from 'lodash.isequal'

import Svg from 'components/Svg'

import {
    ColumnLeft,
    ColumnRight,
    RowBottom,
    CategoryButton,
    ShuffleButton,
    ToggleButton,
    SaveButton,
    RowAlignment,
} from './Styles'

import { ButtonProps, MenuAction, Props } from './Types'
import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'
import { AppDispatch, AppState } from 'store/Types'
import { OutfitSlot } from 'api/Firebase/Types'
import { AvatarActions } from 'store/Avatar'

const LEFT_BUTTONS: ButtonProps[] = [
    {
        action: 'skinColor',
        icon: 'colorPicker',
    },
    {
        action: 'expression',
        icon: 'expression',
    },
    {
        action: 'head',
        icon: 'head',
    },
    {
        action: 'eyewear',
        icon: 'eyewear',
    },
    {
        action: 'background',
        icon: 'background',
    },
]

const RIGHT_BUTTONS: ButtonProps[] = [
    {
        action: 'torsoInner',
        icon: 'torsoInner',
    },
    {
        action: 'torsoOuter',
        icon: 'torsoOuter',
    },
    {
        action: 'legs',
        icon: 'legs',
    },
    {
        action: 'feet',
        icon: 'feet',
    },
    {
        action: 'hands',
        icon: 'hands',
    },
]

const getRandomIndex = (length: number) => Math.floor(Math.random() * length)

const Menu: React.FC<Props> = ({ onAction, activeButton, isVisible }: Props) => {
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true)
    const {
        avatar: { staged, saved, status },
        wearables,
        expressions,
    } = useAvatarSelector<AppState>(state => state)
    const dispatch = useAvatarDispatch<AppDispatch>()

    const handleAction = (action: MenuAction) => {
        onAction(action)
    }

    const handleShuffle = () => {
        const wearableKeys = Object.keys(wearables) as OutfitSlot[]
        wearableKeys.forEach(slot => {
            if (wearables[slot].length > 0) {
                const leaveEmpty = Math.random() < 0.2
                if (leaveEmpty) {
                    dispatch(AvatarActions.updateOutfit({ wearableId: '', file: '', slot }))
                    return
                }
    
                const filteredWearables = wearables[slot].filter(wearable => wearable.accessLevel === 'owned')
                const randomIndex = getRandomIndex(filteredWearables.length)
                const wearable = filteredWearables[randomIndex]
                dispatch(AvatarActions.updateOutfit(wearable))
            }
        })
    }

    useEffect(() => {
        if (isEqual(staged, saved)) {
            setIsSaveDisabled(true)
        } else {
            setIsSaveDisabled(false)
        }
    }, [staged, saved])

    useEffect(() => {
        if (status === 'loading') {
            setIsSaveDisabled(true)
        } else if (status === 'failed') {
            setIsSaveDisabled(false)
        }
    }, [status])

    return (
        <>
            {isVisible && (
                <>
                    <ColumnLeft>
                        {LEFT_BUTTONS.map((button, index) => (
                            <CategoryButton
                                key={button.action}
                                onClick={() => handleAction(button.action)}
                                isActive={activeButton === button.action}
                                index={index}
                            >
                                <Svg name={button.icon} />
                            </CategoryButton>
                        ))}
                    </ColumnLeft>
                    <ColumnRight>
                        {RIGHT_BUTTONS.map((button, index) => (
                            <CategoryButton
                                key={button.action}
                                onClick={() => handleAction(button.action)}
                                isActive={activeButton === button.action}
                                index={index}
                            >
                                <Svg name={button.icon} />
                            </CategoryButton>
                        ))}
                    </ColumnRight>
                </>
            )}
            <RowBottom>
                <RowAlignment>
                    {isVisible && (
                        <>
                            <ShuffleButton onClick={handleShuffle}>
                                <Svg name='shuffle' />
                            </ShuffleButton>
                            <SaveButton
                                onClick={() => !isSaveDisabled && handleAction('saveAvatar')}
                                isDisabled={isSaveDisabled}
                            >
                                Set
                            </SaveButton>
                        </>
                    )}
                    <ToggleButton onClick={() => handleAction('toggleView')}>
                        <Svg name={isVisible ? 'eyeOpen' : 'edit'} />
                    </ToggleButton>
                </RowAlignment>
            </RowBottom>
        </>
    )
}

export default Menu
