import React from 'react'

import Icons from 'icons'

import { Props } from './Types'

const SvgComponent: React.FC<Props> = ({ name }: Props) => {
    if (!name) return null

    const SvgIcon = (Icons as any)[name]
    if (!SvgIcon) return null

    return <SvgIcon />
}
export default SvgComponent
