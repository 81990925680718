import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'

// TODO: update type on exportGroup
export const exportGlb = async (exportGroup: any, asBinary: boolean = true) => {
    const exporter = new GLTFExporter()

    const options = {
        onlyVisible: true,
        binary: asBinary,
    }

    const glbData = await new Promise<ArrayBuffer>((resolve, reject) => {
        exporter.parse(
            exportGroup,
            data => {
                if (data instanceof ArrayBuffer) {
                    resolve(data)
                } else {
                    // TODO: don't know if this actually works, recommended by copilot
                    const gltf = JSON.stringify(data)
                    const buffer = new ArrayBuffer(gltf.length)
                    const view = new Uint8Array(buffer)
                    for (let i = 0; i < gltf.length; ++i) {
                        view[i] = gltf.charCodeAt(i) & 0xff
                    }
                    resolve(buffer)
                }
            },
            error => {
                console.error(`An error occurred while exporting the ${asBinary ? '.glb' : '.gltf'}:`, error)
                reject(error)
            },
            options
        )
    })

    const blob = new Blob([glbData], { type: asBinary ? 'model/gltf-binary' : 'model/gltf+json' })

    return blob
}
