import { PayloadAction } from '@reduxjs/toolkit'

import { AvatarState } from '../Types'
import { ExpressionItem } from 'store/Expressions/Types'

const updateEyes = (state: AvatarState, action: PayloadAction<ExpressionItem>) => {
    return {
        ...state,
        staged: {
            ...state.staged,
            eyes: {
                ...state.staged.eyes,
                expressionId: action.payload.expressionId,
                file: action.payload.file,
            },
        },
    }
}

export default updateEyes
