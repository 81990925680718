import styled from 'styled-components'

const hoverOutline = '0 0 0 var(--leva-borderWidths-focus) var(--leva-colors-accent1)'

export const Container = styled.div`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    canvas {
        width: 100%;
        height: 100%;
    }
    button,
    button:not(:disabled) {
        padding: 5px 7px;
        color: var(--leva-colors-highlight2);
        background-color: var(--toibox-tertiary-bg);
        &:hover {
            box-shadow: ${hoverOutline};
        }
    }

    select + div {
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 3em;
            height: 100%;
            background: linear-gradient(90deg, transparent 0%, var(--leva-colors-elevation3) 40%);
            border-radius: 0 var(--leva-radii-sm) var(--leva-radii-sm) 0;
            cursor: default;
        }
    }
`

export const toiboxTheme = {
    colors: {
        elevation1: '#292d39', // bg color of the root panel (main title bar)
        elevation2: '#181c20', // bg color of the rows (main panel color)
        elevation3: 'var(--toibox-tertiary-bg)', // bg color of the inputs
        accent1: 'var(--toibox-beige)',
        accent2: 'var(--toibox-secondary-bg)',
        accent3: 'var(--toibox-tertiary-bg)',
        highlight1: '#535760',
        highlight2: 'var(--toibox-font-neutral-grey4',
        highlight3: 'var(--toibox-primary-color)',
        vivid1: 'var(--toibox-yellow)',
        folderWidgetColor: '$highlight2',
        folderTextColor: '$highlight3',
        toolTipBackground: '$highlight3',
        toolTipText: '$elevation2',
    },
}
