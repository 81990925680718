import { configureStore } from '@reduxjs/toolkit'
import { ReactReduxContext } from 'react-redux'

import rootReducer from 'store/Reducers'

const { NODE_ENV, REACT_APP_REDUXDEVTOOLS } = process.env

const store = configureStore({
    reducer: rootReducer,
    devTools: NODE_ENV === 'production' ? false : REACT_APP_REDUXDEVTOOLS === 'avatar',
})

export const avatarContext = ReactReduxContext

export default store
