import styled from 'styled-components'

export const ColorContainer = styled.section`
    position: relative;
    display: block;
    padding: 1rem 0.75rem;
`

export const TileContainer = styled.section`
    position: relative;
    display: block;
    padding: 1rem 0;
`
