import { useCallback, useMemo } from 'react'

import HorizontalScroller from '../HorizontalScroller'

import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'
import { AvatarActions } from 'store/Avatar'

import { AppDispatch, AppState } from 'store/Types'
import { ExpressionSlot } from 'api/Firebase/Types'
import { ExpressionItem } from 'store/Expressions/Types'
import { OptionItem } from '../HorizontalScroller/Types'

const Expressions: React.FC = () => {
    const {
        avatar: { staged, saved },
        expressions,
    } = useAvatarSelector<AppState>(state => state)
    const dispatch = useAvatarDispatch<AppDispatch>()

    const handleEyesSelect = useCallback(
        (item: OptionItem) => {
            const slot = 'eyes'
            let existingExpression = expressions[slot].find(
                (expression: ExpressionItem) => expression.expressionId === item.id
            )
            if (!existingExpression) {
                console.warn('no expression found for item', item)
                const defaultItem = expressions[slot][0]
                dispatch(AvatarActions.updateEyes(defaultItem))
            } else {
                dispatch(AvatarActions.updateEyes(existingExpression))
            }
        },
        [expressions]
    )

    const handleMouthSelect = useCallback(
        (item: OptionItem) => {
            const slot = 'mouth'
            let existingExpression = expressions[slot].find(
                (expression: ExpressionItem) => expression.expressionId === item.id
            )
            if (!existingExpression) {
                console.warn('no expression found for item', item)
                const defaultItem = expressions[slot][0]
                dispatch(AvatarActions.updateMouth(defaultItem))
            } else {
                dispatch(AvatarActions.updateMouth(existingExpression))
            }
        },
        [expressions]
    )

    const createExpressionItemsForSlot = useMemo(
        () => (slot: ExpressionSlot) => {
            const optionItems: OptionItem[] = []
            expressions[slot].forEach((expression: ExpressionItem) => {
                const isSaved = saved[slot].expressionId === expression.expressionId
                optionItems.push({
                    id: expression.expressionId,
                    name: expression.name,
                    thumbnail: expression.thumbnail,
                    status: isSaved
                        ? 'saved'
                        : staged[slot].expressionId === expression.expressionId
                        ? 'staged'
                        : 'none',
                })
            })
            return optionItems
        },
        [expressions, saved.eyes, saved.mouth, staged.eyes, staged.mouth]
    )

    const eyesOptions = createExpressionItemsForSlot('eyes')
    const mouthOptions = createExpressionItemsForSlot('mouth')

    return (
        <>
            <HorizontalScroller items={eyesOptions} title='Eyes' rowCount={1} onSelect={handleEyesSelect} />
            <HorizontalScroller items={mouthOptions} title='Mouth' rowCount={1} onSelect={handleMouthSelect} />
        </>
    )
}

export default Expressions
