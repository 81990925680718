import { combineReducers } from 'redux'

import { AvatarReducer } from './Avatar'
import { ExpressionsReducer } from './Expressions'
import { WearablesReducer } from './Wearables'
import { BackgroundsReducer } from './Backgrounds'

const rootReducer = combineReducers({
    avatar: AvatarReducer,
    expressions: ExpressionsReducer,
    wearables: WearablesReducer,
    backgrounds: BackgroundsReducer
})

export default rootReducer
