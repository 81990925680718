import { ReactComponent as background } from './background.svg'
import { ReactComponent as colorPicker } from './colorPicker.svg'
import { ReactComponent as cross } from './cross.svg'
import { ReactComponent as edit } from './edit.svg'
import { ReactComponent as expression } from './expression.svg'
import { ReactComponent as eyeOpen } from './eyeOpen.svg'
import { ReactComponent as eyeShut } from './eyeShut.svg'
import { ReactComponent as eyewear } from './eyewear.svg'
import { ReactComponent as feet } from './feet.svg'
import { ReactComponent as hands } from './hands.svg'
import { ReactComponent as head } from './head.svg'
import { ReactComponent as legs } from './legs.svg'
import { ReactComponent as shuffle } from './shuffle.svg'
import { ReactComponent as torsoInner } from './torsoInner.svg'
import { ReactComponent as torsoOuter } from './torsoOuter.svg'

const Icons = {
    background,
    colorPicker,
    cross,
    edit,
    expression,
    eyeOpen,
    eyeShut,
    eyewear,
    feet,
    hands,
    head,
    legs,
    shuffle,
    torsoInner,
    torsoOuter,
}

export default Icons
