import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, child, get, query, orderByChild, DatabaseReference } from 'firebase/database'

import saveUserWearablesByUserIdThunk from './saveUserWearablesThunk'

import Routes from 'api/Firebase/Routes'

import { GetWearablesParams, WearableItem, WearablesByRarity, WearablesState } from '../Types'
import { OutfitSlot, Rarity, UserWearable, Wearable, WearablesById } from 'api/Firebase/Types'

const getAllWearables = async (databaseRef: DatabaseReference, orderBy?: keyof Wearable) => {
    const DATABASE = databaseRef

    // NOTE: Firebase requires you set a .indexOn rule in your database rules if you want to use orderByChild
    const constraint = orderBy ? orderByChild(orderBy) : orderByChild('slot')
    const firebaseWearablesRef = await get(query(child(DATABASE, Routes.wearablesAll())))
    const firebaseWearables = firebaseWearablesRef.val() as WearablesById

    const wearables: WearablesState = {
        eyewear: [],
        feet: [],
        hands: [],
        head: [],
        legs: [],
        torsoInner: [],
        torsoOuter: [],
    }

    if (!firebaseWearables) {
        return wearables
    }

    Object.keys(firebaseWearables).forEach((wearableId: uid) => {
        const item: WearableItem = {
            ...firebaseWearables[wearableId],
            wearableId,
            accessLevel: 'hidden', // default until we apply the user's access level
        }
        wearables[item.slot as OutfitSlot].push(item)
    })

    return wearables
}

const generateInitialUserWearables = async (databaseRef: DatabaseReference) => {
    const results: WearablesState = {
        eyewear: [],
        feet: [],
        hands: [],
        head: [],
        legs: [],
        torsoInner: [],
        torsoOuter: [],
    }
    const allWearables = await getAllWearables(databaseRef, 'rarity')
    const slots = Object.keys(allWearables) as OutfitSlot[]
    const rarityOrder: Rarity[] = ['common', 'rare', 'limited']
    slots.forEach((slot: OutfitSlot) => {
        const items: WearablesByRarity = {
            common: [],
            rare: [],
            limited: [],
        }
        allWearables[slot].forEach((wearable: WearableItem) => {
                if (!wearable.hasOwnProperty('toibucks') || wearable.toibucks === 0) {
                    items[wearable.rarity].push({
                        ...wearable,
                        accessLevel: 'owned',
                    })
                } else {
                    items[wearable.rarity].push(wearable)
                }
        })

        const maxInitial = Math.min(300, allWearables[slot].length)

        for (let i = 0; i < maxInitial; i++) {
            // generate a random number and use it to determine rarity
            const rarityChance = Math.random()
            let rarity: number = 0
            if (rarityChance < 0.08) {
                rarity = 2
            } else if (rarityChance < 0.3) {
                rarity = 1
            }

            // if there are no items of this rarity, decrement rarity and try again
            while (rarity > -1 && items[rarityOrder[rarity]].length === 0) {
                rarity--
            }
            // if rarity is 0, we've tried all rarities and there are no items
            if (rarity < 0) {
                break
            }
            if (items[rarityOrder[rarity]].length === 0) {
                break
            }
            // if a rarity has items, pop the last item off the array and add it to the results
            results[slot].push(items[rarityOrder[rarity]].pop()!)
        }
    })
    return results
}

const getUserWearablesThunk = createAsyncThunk(
    'wearables/getUserWearables',
    async ({ userId, accessToken, firebaseDb }: GetWearablesParams, { dispatch }) => {
        const DATABASE = ref(firebaseDb)
        const firebaseUserWearablesRef = await get(child(DATABASE, Routes.userWearables(userId)))
        const firebaseUserWearables = firebaseUserWearablesRef.val() as UserWearable | null
        const initialUserWearables = await generateInitialUserWearables(DATABASE)
        if (!firebaseUserWearables) {
            dispatch(
                saveUserWearablesByUserIdThunk({ userId, userWearables: initialUserWearables, accessToken, firebaseDb })
            )
            return initialUserWearables
        }

        const firebaseWearablesRef = await get(child(DATABASE, Routes.wearablesAll()))
        const firebaseWearables = firebaseWearablesRef.val()
        const userWearables: WearablesState = {
            eyewear: [],
            feet: [],
            hands: [],
            head: [],
            legs: [],
            torsoInner: [],
            torsoOuter: [],
        }
        const userWearablesKeys = Object.keys(initialUserWearables) as OutfitSlot[]
        userWearablesKeys.forEach((slot: OutfitSlot) => {
            Object.keys(initialUserWearables[slot]).forEach(wearableId => {
                if (firebaseWearables[wearableId]) {
                    const item: WearableItem = {
                        ...firebaseWearables[wearableId],
                        accessLevel: firebaseUserWearables[slot][wearableId],
                        wearableId,
                    }
                    if (item.accessLevel === 'owned') {
                        userWearables[item.slot as OutfitSlot].push(item)
                    }
                }
                initialUserWearables[slot].forEach(item => {
                    if (!userWearables[slot].find(userItem => userItem.wearableId === item.wearableId) && (!item.hasOwnProperty('toibucks') || item.toibucks === 0)) {
                        userWearables[slot].push({
                            ...item,
                            accessLevel: 'owned'
                    });
                    }
                })
            })
        })
        return userWearables
    }
)

export default getUserWearablesThunk
