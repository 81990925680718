import { createSlice } from '@reduxjs/toolkit'

import getBackgroundsThunk from './Actions/getBackgroundsThunk'

import { BackgroundsState } from './Types'

const initialState: BackgroundsState = []

const backgroundsSlice = createSlice({
    name: 'backgrounds',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getBackgroundsThunk.pending, state => {
            // TODO: change loading state here
            return state
        })
        builder.addCase(getBackgroundsThunk.fulfilled, (state, action) => {
            return action.payload
        })
        builder.addCase(getBackgroundsThunk.rejected, state => {
            // TODO: pop toast here
            return state
        })
    },
})

export const BackgroundsActions = { ...backgroundsSlice.actions, getBackgroundsThunk }
export const BackgroundsReducer = backgroundsSlice.reducer
