import { PayloadAction } from '@reduxjs/toolkit'

import { AvatarState } from '../Types'
import { Background } from 'api/Firebase/Types'

const updateBackground = (state: AvatarState, action: PayloadAction<Background>) => {
    const { backgroundId, file } = action.payload
    return {
        ...state,
        staged: {
            ...state.staged,
            background: {
                backgroundId,
                file,
            },
        },
    }
}

export default updateBackground
