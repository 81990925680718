export type Props = {
    lookAt: LookAtKey
    editor?: boolean
    preview?: boolean
}

export const LOOK_ATS = [
    'orbit',
    'snapshot',
    'fullBody',
    'skinColor',
    'expression',
    'background',
    'eyewear',
    'feet',
    'hands',
    'head',
    'legs',
    'torsoInner',
    'torsoOuter',
] as const
export type LookAtTuple = typeof LOOK_ATS
export type LookAtKey = LookAtTuple[number]

export type CamData = {
    [key in LookAtKey]: {
        pos: [number, number, number]
        target: [number, number, number]
    }
}
