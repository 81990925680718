import { createSlice } from '@reduxjs/toolkit'

import getUserWearablesThunk from './Actions/getUserWearablesThunk'
import saveUserWearablesByUserIdThunk from './Actions/saveUserWearablesThunk'

import { WearablesState } from './Types'

const initialState: WearablesState = {
    eyewear: [],
    feet: [],
    hands: [],
    head: [],
    legs: [],
    torsoInner: [],
    torsoOuter: [],
}

const wearablesSlice = createSlice({
    name: 'wearables',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getUserWearablesThunk.pending, state => {
            // TODO: change loading state here
            return state
        })
        builder.addCase(getUserWearablesThunk.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        })
        builder.addCase(getUserWearablesThunk.rejected, state => {
            // TODO: pop toast here
            return state
        })

        builder.addCase(saveUserWearablesByUserIdThunk.pending, state => {
            // TODO: change loading state here
            return state
        })
        builder.addCase(saveUserWearablesByUserIdThunk.fulfilled, state => {
            // ignore updating state, this is only for saving to firebase
            return state
        })
        builder.addCase(saveUserWearablesByUserIdThunk.rejected, state => {
            // TODO: pop toast here
            return state
        })
    },
})

export const WearablesActions = { ...wearablesSlice.actions, saveUserWearablesByUserIdThunk, getUserWearablesThunk }
export const WearablesReducer = wearablesSlice.reducer
