import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;

    .react-colorful {
        width: 100%;
    }
    .react-colorful__saturation {
        margin: 1rem 0 0;
        border-radius: 5px;
    }
    .react-colorful__hue {
        order: -1;
        border-radius: 5px;
    }
    .react-colorful__pointer {
        width: 33px;
        height: 33px;
    }
`
