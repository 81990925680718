import React, { useRef, useEffect, useState } from 'react'
import { BackSide, DirectionalLight, Texture, PointLight } from 'three'
import { SoftShadows, useGLTF } from '@react-three/drei'
import { Euler } from '@react-three/fiber'

import useLoadingTexture from 'utils/useLoadingTexture'

import { Props } from './Types'

import ASSETS from 'assets'

const {
    meshes: { platform: PLATFORM_MESH_URL },
} = ASSETS

const ENVIRONMENT_ROTATION: Euler = [0, -1.7, 0]
const ENVIRONMENT_SCALE: number = 100

// currently, this contains everything visual related to the avatar background scene, including geometry, lights, and shadows
const Background3Component: React.FC<Props> = ({ editor, textureUrl, onLoading }: Props) => {
    const [backgroundTexture, setBackgroundTexture] = useState<Texture>(new Texture())
    const [loadingTexture, isLoadingTexture] = useLoadingTexture(editor ? textureUrl : '')

    const { scene }: GLTFExtended = useGLTF(PLATFORM_MESH_URL)

    scene.traverse((object: any) => {
        if (object.isMesh) {
            object.receiveShadow = true
        }
    })

    // wait until texture is fully loaded before assigning to background mesh
    useEffect(() => {
        if (editor) {
            const backgroundReady = !!loadingTexture && !isLoadingTexture
            onLoading({ background: !backgroundReady })
            if (backgroundReady) {
                setBackgroundTexture(loadingTexture)
            }
        } else {
            setBackgroundTexture(backgroundTexture)
        }
    }, [textureUrl, isLoadingTexture])

    const mainLight = useRef<DirectionalLight>(null!)
    // useHelper(mainLight, DirectionalLightHelper, 4, 'hotpink')

    const rimLight = useRef<DirectionalLight>(null!)
    // useHelper(rimLight, DirectionalLightHelper, 4, 'teal')

    const lowerLight = useRef<PointLight>(null!)
    // useHelper(lowerLight, PointLightHelper, 4, 'red')

    return (
        <>
            {/* platform mesh is loaded from glb, but in the future we may put more geometry and lights into the loaded asset */}
            { editor && <primitive object={scene} /> }

            {/* background sphere with environment image (not providing light) */}
            <mesh name='background' rotation={ENVIRONMENT_ROTATION} scale={ENVIRONMENT_SCALE}>
                <sphereGeometry />
                <meshBasicMaterial transparent map={backgroundTexture} side={BackSide} toneMapped={false} />
            </mesh>

            {/* key light from the front that provides shadows */}
            <ambientLight intensity={0.25} />
            <directionalLight
                ref={mainLight}
                intensity={0.6}
                position={[1, 0.75, 3.25]}
                castShadow
                shadow-mapSize-height={2048}
                shadow-mapSize-width={2048}
                shadowBias={-0.0001}
                shadow-camera-near={0.1}
                shadow-camera-far={10}
                shadow-camera-left={-4}
                shadow-camera-right={4}
                shadow-camera-top={4}
                shadow-camera-bottom={-4}
            />

            {/* rim light from behind (not casting shadows) */}
            <directionalLight ref={rimLight} intensity={0.75} position={[-2.4, 1.3, -3.25]} />

            {/* lower directional light for shoe details (no shadows) */}
            <pointLight
                ref={lowerLight}
                intensity={4} 
                castShadow={false}
                power={50}
                position={[0, 0.01, 1]}
                distance={1.7}
                color={'white'}
            />

            {/* soft shadows */}
            <SoftShadows size={25} samples={25} />
        </>
    )
}

export default Background3Component
