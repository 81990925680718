import { PayloadAction } from '@reduxjs/toolkit'

import { AvatarState } from '../Types'

const updateSkinColor = (state: AvatarState, action: PayloadAction<string>) => {
    return {
        ...state,
        staged: {
            ...state.staged,
            skinColor: action.payload,
        },
    }
}

export default updateSkinColor
