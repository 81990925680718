import React from 'react'
import AvatarEditor from 'components/AvatarEditor'

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_AUTH_API_KEY,
  appId: process.env.REACT_APP_MAIN_APP_ID,
  authDomain: process.env.REACT_APP_MAIN_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_MAIN_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_MAIN_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_MAIN_PROJECT_ID,
  storageBucket: process.env.REACT_APP_MAIN_STORAGE_BUCKET,
}

const EditorView: React.FC = () => {
  // TODO: update this when actual auth is required
  const userId = process.env.REACT_APP_DEV_USER_ID || ''
  const accessToken = ''

  if (!userId) {
    return <div>Missing user id</div>
  }
  return <AvatarEditor
    onSaveCallback={undefined}
    backgroundColor={'#212121'}
    animFilename={undefined}
    animName={undefined}
    editor={true}
    userId={userId}
    accessToken={accessToken}
    firebaseConfig={FIREBASE_CONFIG}
    storageBaseUrl={process.env.REACT_APP_AVATAR_S3_URL as string}
    lambdaUrl={process.env.REACT_APP_AVATAR_LAMBDA_URL as string}
    assetUrl={process.env.REACT_APP_AVATAR_UPLOAD_URL as string}
  />
}

export default EditorView
