import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, child, set } from 'firebase/database'

import Routes from 'api/Firebase/Routes'

import { SaveUserWearablesByIdPlayload } from '../Types'
import { OutfitSlot, UserWearable } from 'api/Firebase/Types'
import { AppState } from 'store/Types'

const saveUserWearablesByUserIdThunk = createAsyncThunk(
    'wearables/saveUserWearablesById',
    async ({ userId, userWearables, firebaseDb }: SaveUserWearablesByIdPlayload, { getState }) => {
        if (!userWearables) {
            const { wearables } = getState() as AppState
            userWearables = wearables
        }

        const firebaseUserWearablesUpdate = {} as UserWearable
        const userWearableKeys = Object.keys(userWearables) as OutfitSlot[]
        userWearableKeys.forEach((slot: OutfitSlot) => {
            firebaseUserWearablesUpdate[slot] = {}
            userWearables[slot].forEach(item => {
                firebaseUserWearablesUpdate[slot][item.wearableId] = item.accessLevel
            })
        })

        const DATABASE = ref(firebaseDb)
        await set(child(DATABASE, Routes.userWearables(userId)), firebaseUserWearablesUpdate)
        return firebaseUserWearablesUpdate
    }
)

export default saveUserWearablesByUserIdThunk
