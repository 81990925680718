import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Grid, FreeMode } from 'swiper'

import { Container, OptionTile, TitleText } from './Styles'
import { Props } from './Types'
import Svg from 'components/Svg'

const { REACT_APP_AVATAR_S3_URL } = process.env

const HorizontalScroller: React.FC<Props> = ({ items, title, rowCount = 2, onSelect }: Props) => {
    return (
        <Container>
            {title && <TitleText>{title}</TitleText>}

            <Swiper
                modules={[Grid, FreeMode]}
                direction={'horizontal'}
                slidesPerView={'auto'}
                initialSlide={0}
                freeMode={{
                    enabled: true,
                    sticky: true,
                }}
                grid={{
                    rows: rowCount,
                    fill: 'row',
                }}
            >
                {items.map((item, index: number) => {
                    const isNone = item.name === 'none' && item.id === ''
                    return (
                        <SwiperSlide key={index}>
                            <OptionTile
                                key={index}
                                onClick={() => onSelect(item)}
                                status={item.status}
                                isNone={isNone}
                                image={!isNone && item.thumbnail ? `${REACT_APP_AVATAR_S3_URL}/${item.thumbnail}` : ''}
                            >
                                {isNone && <Svg name={item.thumbnail} />}
                            </OptionTile>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Container>
    )
}

export default HorizontalScroller
