import ColorPicker from 'components/ColorPicker'
import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'
import { AppDispatch, AppState } from 'store/Types'
import { AvatarActions } from 'store/Avatar'
import { Props } from './Types'

const SkinColor: React.FC<Props> = (props: Props) => {
    const {
        avatar: { staged },
    } = useAvatarSelector<AppState>(state => state)
    const dispatch = useAvatarDispatch<AppDispatch>()

    const handleSkinColorSelect = (color: string) => {
        dispatch(AvatarActions.updateSkinColor(color))
    }

    return <ColorPicker color={staged.skinColor} onColorChange={handleSkinColorSelect} />
}

export default SkinColor
