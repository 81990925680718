import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, get } from 'firebase/database'

import Routes from 'api/Firebase/Routes'
import { getAvatarParams } from '../Types'

const getAvatarByUserIdThunk = createAsyncThunk(
    'avatar/getAvatarByUserId',
    async ({ userId, firebaseDb }: getAvatarParams) => {
        const userAvatarRoute = Routes.userAvatars(userId)
        const userAvatarRef = ref(firebaseDb, userAvatarRoute)

        const snapshot = await get(userAvatarRef)
        const data = snapshot.val()
        if (data) {
            return data
        }
    }
)

export default getAvatarByUserIdThunk
