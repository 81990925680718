import { useState, useEffect } from 'react'
import { TextureLoader, Texture } from 'three'

const useLoadingTexture = (url: string): [Texture | null, boolean] => {
    const loader = new TextureLoader()
    const [texture, setTexture] = useState<Texture | null>(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!url) return
        setIsLoading(true)
        loader.load(
            url,
            tex => {
                setTexture(tex)
                setIsLoading(false)
            },
            undefined,
            error => {
                console.error('An error occurred while loading texture.', error)
                setIsLoading(false)
            }
        )
    }, [url])

    return [texture, isLoading]
}

export default useLoadingTexture
