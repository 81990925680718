import SkinColor from './SkinColor'
import Expressions from './Expressions'
import Wearables from './Wearables'

import { Props } from './Types'
import { ColorContainer, TileContainer } from './Styles'
import Backgrounds from './Backgrounds/Backgrounds'

const Options: React.FC<Props> = ({ optionCategory }: Props) => {
    if (optionCategory === 'skinColor') {
        return (
            <ColorContainer>
                <SkinColor />
            </ColorContainer>
        )
    } else if (optionCategory === 'expression') {
        return (
            <TileContainer>
                <Expressions />
            </TileContainer>
        )
    } else if (optionCategory === 'background') {
        return (
            <TileContainer>
                <Backgrounds />
            </TileContainer>
        )
    }
    else if (optionCategory === 'snapshot' || optionCategory === 'fullBody') {
        return <></>;
    }
     else {
        return (
            <TileContainer>
                <Wearables optionCategory={optionCategory} />
            </TileContainer>
        )
    }
}

export default Options
