import './variables.css'

import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Pages from 'pages'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Pages.Preview />} />
            <Route path='/editor' element={<Pages.Editor />} />
        </Routes>
    </BrowserRouter>
)
