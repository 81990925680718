import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, child, get } from 'firebase/database'

import Routes from 'api/Firebase/Routes'

import { ExpressionsState, GetExpressionsParams } from '../Types'
import { ExpressionSlot } from 'api/Firebase/Types'

const getExpressionsThunk = createAsyncThunk(
    'expressions/getExpressions',
    async ({ firebaseDb }: GetExpressionsParams) => {
        const DATABASE = ref(firebaseDb)
        const expressionsRef = await get(child(DATABASE, Routes.expressionsAll()))
        const results = expressionsRef.val()

        const update: ExpressionsState = {
            eyes: [],
            mouth: [],
        }
        Object.keys(results).forEach(expressionId => {
            const item = {
                ...results[expressionId],
                expressionId,
            }
            update[item.slot as ExpressionSlot].push(item)
        })

        return update
    }
)

export default getExpressionsThunk
