import styled, { css } from 'styled-components'
import { ButtonStyle, SaveButtonStyle } from './Types'

const margin = 0.5
const buttonTileSize = 2.9
const saveButtonHeight = 3.1

const buttonTile = css`
    position: relative;
    width: ${buttonTileSize}rem;
    height: ${buttonTileSize}rem;
    padding: 0.5rem;
    margin-bottom: ${margin}rem;
    background: var(--mtb-avatar-button-menu-fill);
    box-shadow: 0px 1px 2px var(--mtb-avatar-button-menu-shadow);
    backdrop-filter: blur(2.5px);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    tap-highlight-color: transparent;
    -webkit-tap-highlight-color: transparent;
`

const svgBase = css`
    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: var(--mtb-avatar-button-menu-icon);
    }
`

// note: currently this overlay menu needs to cover the entire canvas
// to prevent touch on the canvas below
const ButtonCol = styled.div`
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const ColumnLeft = styled(ButtonCol)`
    left: ${margin}rem;
`

export const ColumnRight = styled(ButtonCol)`
    align-items: end;
    right: ${margin}rem;
`

const getActiveState = ({ isActive, index = 0 }: ButtonStyle) => {
    if (isActive) {
        return css`
            background: var(--mtb-avatar-button-menu-fill-active);
            transform: rotate(${index % 2 === 0 ? '-' : ''}4deg);
            svg {
                fill: var(--mtb-avatar-button-menu-icon-active);
            }
        `
    }
    return css``
}

export const CategoryButton = styled.button<ButtonStyle>`
    ${buttonTile};
    ${svgBase};
    ${getActiveState};
    transition: transform 150ms ease-out, backdropFilter 150ms ease-out, background 150ms ease-out;

    &:active {
        transform: scale3d(0.95, 0.95, 0.95);
        transition: none;
    }
`

export const RowBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${saveButtonHeight}rem;
    margin-bottom: ${margin}rem;
`

export const RowAlignment = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100%;
    padding: 0 ${margin * 2 + buttonTileSize}rem;
`

const getDisabledState = ({ isDisabled }: SaveButtonStyle) => {
    if (isDisabled) {
        return css`
            background: var(--mtb-avatar-button-secondary-fill);
            cursor: not-allowed;
        `
    }
    return css`
        background: var(--mtb-avatar-button-primary-fill);
        cursor: pointer;
    `
}

export const SaveButton = styled.button<SaveButtonStyle>`
    display: flex;
    min-height: 100%;
    width: 100%;
    font-size: 1.2rem;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 2rem;
    font-weight: 700;
    letter-spacing: 0.05rem;
    line-height: 0;
    color: var(--mtb-avatar-button-primary-text);

    ${getDisabledState};
`

export const ToggleButton = styled.button`
    position: absolute;
    width: ${saveButtonHeight}rem;
    height: ${saveButtonHeight}rem;
    padding: 0.5rem;
    right: ${margin - 0.1}rem;
    background: var(--mtb-avatar-button-menu-fill);
    box-shadow: 0px 1px 2px var(--mtb-avatar-button-menu-shadow);
    backdrop-filter: blur(2.5px);
    border: none;
    border-radius: 10px;
    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: var(--mtb-avatar-button-menu-toggle-icon);
    }
`

export const ShuffleButton = styled.button`
    position: absolute;
    width: ${saveButtonHeight}rem;
    height: ${saveButtonHeight}rem;
    padding: 0.5rem;
    left: ${margin - 0.1}rem;
    background: var(--mtb-avatar-button-menu-fill);
    box-shadow: 0px 1px 2px var(--mtb-avatar-button-menu-shadow);
    backdrop-filter: blur(2.5px);
    border: none;
    border-radius: 10px;
    svg {
        fill: var(--mtb-avatar-button-primary-text);
    }
`
