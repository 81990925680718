import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, child, get } from 'firebase/database'

import FirebaseService from 'api/Firebase'
import Routes from 'api/Firebase/Routes'

import { BackgroundsState, GetBackgroundsParams } from '../Types'

const getBackgroundsThunk = createAsyncThunk(
    'backgrounds/getBackgrounds',
    async ({ firebaseDb }: GetBackgroundsParams) => {
        const DATABASE = ref(firebaseDb)
        const backgroundsRef = await get(child(DATABASE, Routes.backgroundsAll()))
        const results = backgroundsRef.val()

        const update: BackgroundsState = []
        Object.keys(results).forEach(backgroundId => {
            const item = {
                ...results[backgroundId],
                backgroundId,
            }
            update.push(item)
        })

        return update
    }
)

export default getBackgroundsThunk
