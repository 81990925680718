import { Provider } from 'react-redux'

import AvatarEditorComponent from './AvatarEditor'

import store, { avatarContext } from 'store'

import { Props } from './Types'

const AvatarEditor: React.FC<Props> = (props: Props) => {
    return (
        <Provider store={store} context={avatarContext}>
            <AvatarEditorComponent {...props} />
        </Provider>
    )
}

export default AvatarEditor
