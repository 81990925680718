export const handleAssetUpload = async (action: string, contentType: string, blob: Blob, accessToken: string, endPoint: string) => {
    const urlResponse = await fetch(endPoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
            action,
            token: accessToken,
            payload: {},
        }),
    })
    const { putUrl, key } = await urlResponse.json()

    // put blob contents to s3
    if (!putUrl || !key) {
        throw new Error('Unable to generate upload putUrl')
    }
    const uploadResponse = await fetch(putUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': contentType,
        },
        body: blob,
    })
    if (!uploadResponse.ok) {
        throw new Error('Upload failed')
    }

    return key
}
