import { createAsyncThunk } from '@reduxjs/toolkit'
import { ref, child, push, update } from 'firebase/database'

import Routes from 'api/Firebase/Routes'

import { SaveAvatarParams } from '../Types'
import { AppState } from '../../Types'
import { Outfit } from 'api/Firebase/Types'

import { handleAssetUpload } from 'utils/handleAssetUpload'

const saveAvatarByUserIdThunk = createAsyncThunk(
    'avatar/saveAvatarByUserIdThunk',
    async ({ userId, accessToken, thumbnailBlob, firebaseDb, lambdaUrl, assetUrl }: SaveAvatarParams, { getState }) => {
        const DATABASE = ref(firebaseDb)
        const {
            avatar: {
                staged,
                staged: { eyes, mouth, skinColor, outfit, background },
            },
        } = getState() as AppState

        const updatedOutfit = { ...outfit }
        const updatedAvatar = { ...staged }

        const simplifiedOutfit: Outfit = {
            eyewearId: updatedOutfit.eyewear.wearableId,
            feetId: updatedOutfit.feet.wearableId,
            handsId: updatedOutfit.hands.wearableId,
            headId: updatedOutfit.head.wearableId,
            legsId: updatedOutfit.legs.wearableId,
            torsoInnerId: updatedOutfit.torsoInner.wearableId,
            torsoOuterId: updatedOutfit.torsoOuter.wearableId,
        }

        if (!updatedOutfit.userOutfitId) {
            const outfitRef = push(child(DATABASE, Routes.userOutfits(userId)))
            const newOutfitId = outfitRef.key
            if (!newOutfitId) {
                throw new Error('An userOutfitId could not be determined or created')
            }
            updatedOutfit.userOutfitId = newOutfitId
            updatedAvatar.outfit = updatedOutfit
        }

        const thumbnail = await handleAssetUpload('putThumbnailUrl', 'image/png', thumbnailBlob, accessToken, lambdaUrl)
        updatedAvatar.thumbnail = thumbnail

        if (window.parent) {
          window.parent.postMessage({
            type: 'avatar',
            action: 'avatar-update',
            data: {
              userId,
              imageUrl: `${assetUrl}/${thumbnail}`
            }
          }, '*')
        }

        const updateObj: any = {
            [Routes.userAvatars(userId, 'eyes')]: eyes,
            [Routes.userAvatars(userId, 'mouth')]: mouth,
            [Routes.userAvatars(userId, 'skinColor')]: skinColor,
            [Routes.userAvatars(userId, 'outfit')]: updatedOutfit,
            [Routes.userOutfits(userId, updatedOutfit.userOutfitId)]: simplifiedOutfit,
            [Routes.userAvatars(userId, 'thumbnail')]: thumbnail,
            [Routes.userAvatars(userId, 'background')]: background,
        }

        await update(DATABASE, updateObj)

        return updatedAvatar
    }
)

export default saveAvatarByUserIdThunk
