import { useCallback, useMemo } from 'react'

import HorizontalScroller from '../HorizontalScroller'

import { AvatarActions } from 'store/Avatar'
import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'

import { AppDispatch, AppState } from 'store/Types'
import { WearableItem } from 'store/Wearables/Types'
import { OutfitSlot } from 'api/Firebase/Types'
import { Props } from './Types'
import { OptionItem } from '../HorizontalScroller/Types'

const NONE_ICONS = {
    head: 'head',
    eyewear: 'eyewear',
    torsoInner: 'torsoInner',
    torsoOuter: 'torsoOuter',
    legs: 'legs',
    feet: 'feet',
    hands: 'hands',
}

const Wearables: React.FC<Props> = ({ optionCategory }: Props) => {
    const {
        avatar: { saved, staged },
        wearables,
    } = useAvatarSelector<AppState>(state => state)
    const dispatch = useAvatarDispatch<AppDispatch>()

    const handleWearableSelect = useCallback(
        (item: OptionItem) => {
            const slot = optionCategory as OutfitSlot
            let existingWearable = wearables[slot].find((wearable: WearableItem) => wearable.wearableId === item.id)
            if (!existingWearable) {
                const noneItem: WearableItem = {
                    slot: slot,
                    name: 'none',
                    thumbnail: '',
                    wearableId: '',
                    accessLevel: 'hidden',
                    rarity: 'common',
                    file: '',
                }
                dispatch(AvatarActions.updateOutfit(noneItem))
            } else {
                dispatch(AvatarActions.updateOutfit(existingWearable))
            }
        },
        [wearables, optionCategory]
    )

    const createWearableItemsForSlot = useMemo(
        () => (slot: OutfitSlot) => {
            const optionItems: OptionItem[] = []
            const noneItem: OptionItem = {
                name: 'none',
                thumbnail: NONE_ICONS[slot],
                id: '',
                status:
                    saved.outfit[slot].wearableId === ''
                        ? 'saved'
                        : staged.outfit[slot].wearableId === ''
                        ? 'staged'
                        : 'none',
            }
            optionItems.push(noneItem)
            wearables[slot].forEach((wearable: WearableItem) => {
                const isSaved = saved.outfit[slot].wearableId === wearable.wearableId
                if (wearable.accessLevel === 'owned') {
                    optionItems.push({
                        id: wearable.wearableId,
                        name: wearable.name,
                        thumbnail: wearable.thumbnail,
                        status: isSaved
                            ? 'saved'
                            : staged.outfit[slot].wearableId === wearable.wearableId
                            ? 'staged'
                            : 'none',
                    })
                }
            })
            return optionItems
        },
        [wearables, saved.outfit, staged.outfit]
    )

    const items = createWearableItemsForSlot(optionCategory as OutfitSlot)

    return <HorizontalScroller items={items} onSelect={handleWearableSelect} />
}

export default Wearables
