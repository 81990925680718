import React, { useEffect, useRef, useState } from 'react'
import { useAnimations, useGLTF } from '@react-three/drei'
import * as THREE from 'three'

import Wearable from './Wearable'

import useLoadingTexture from 'utils/useLoadingTexture'

import { Props } from './Types'
import { CanvasTexture, MeshStandardMaterial } from 'three'

import ASSETS from 'assets'
const {
    meshes: { body: MODEL_URL },
    animations: { animations: ANIMATIONS_URL, handAnims: HAND_ANIMS, makerAnims: MAKER_ANIMS },
    textures: { blank: BLANK_TEXTURE },
} = ASSETS

export const IDLE_ANIMATION_NAME = 'IDLE'

const Avatar3Component: React.FC<Props> = ({
    animationName,
    animationUrl,
    animationFilename,
    bodyUrl,
    eyesUrl,
    mouthUrl,
    onAnimationLoop,
    skinColor,
    wearables,
    onLoading,
    staged,
    previewHandShape,
    ...props
}: Props) => {
    const groupRef = useRef<THREE.Group>(null)
    const bodyMeshRef = useRef<THREE.SkinnedMesh>()
    const expressionMeshRef = useRef<THREE.SkinnedMesh>()

    const [eyesTexture, isEyesTextureLoading] = useLoadingTexture(eyesUrl || BLANK_TEXTURE)
    const [mouthTexture, isMouthTextureLoading] = useLoadingTexture(mouthUrl || BLANK_TEXTURE)

    const [handStatus, setHandStatus] = useState<string>('none')

    const { scene }: GLTFExtended = useGLTF(bodyUrl || MODEL_URL)
    const { animations }: GLTFExtended = useGLTF(animationUrl || ANIMATIONS_URL)
    const handData: GLTFExtended = useGLTF(HAND_ANIMS)
    const { actions, mixer } = useAnimations(animations, groupRef)
    const handAnims = useAnimations(handData.animations, groupRef)
    const handActions = handAnims.actions;
    const chosenFile: GLTFExtended = animationFilename && animationFilename === 'makerAnims' ? useGLTF(MAKER_ANIMS) : null
    const chosenAnims = chosenFile ? useAnimations(chosenFile.animations, groupRef) : null

    const handleAnimationLoop = (animationName: string) => {
        if (animationName !== IDLE_ANIMATION_NAME) {
            onAnimationLoop && onAnimationLoop()
        }
    }

    useEffect(() => {
        if (scene) {
            const bodyMesh = scene.getObjectByName('body_mesh') as THREE.SkinnedMesh
            if (!bodyMesh) {
                console.warn('body_mesh not found')
            }
            bodyMeshRef.current = bodyMesh
            const expressionMesh = scene.getObjectByName('expression_mesh') as THREE.SkinnedMesh
            if (!expressionMesh) {
                console.warn('expression_mesh not found')
            }
            expressionMeshRef.current = expressionMesh
        }
    }, [scene])
    
    useEffect(() => {
        const activeAnimationFile = chosenAnims ? chosenAnims.actions : actions
        const activeAnimation = animationName || IDLE_ANIMATION_NAME
        const clip = activeAnimationFile !== actions ? activeAnimationFile[activeAnimation] : actions[activeAnimation]
        
        if (!clip) {
            onLoading({ exporting: true })
            return
        } else {
            onLoading({ exporting: false })
        }

        
        const handShape: string = previewHandShape ? previewHandShape : staged?.outfit.hands.handShape
        
        if (!handShape || handShape === 'none' || handShape === '') {
            setHandStatus('HANDS_NATURAL')
        } else if (handShape === 'grasped') {
            setHandStatus('HANDS_GRASP')
        } else if (handShape === 'cupped') {
            setHandStatus('HANDS_CUPPING')
        } else if (handShape === 'fist') {
            setHandStatus('HANDS_FIST')
        }
        
        if (!animationUrl && previewHandShape && handStatus !== 'none') {
            mixer.stopAllAction()
            const clonedHandsClip = handActions[handStatus]!.getClip().clone()
            const handsClip = mixer.clipAction(clonedHandsClip)
            handsClip.reset().fadeIn(0.5).play()
            return
        }

        const animClip = clip.getClip().clone();
        
        if (handActions) {
            const handSpecificAnim = handActions[handStatus]?.getClip().tracks
                .filter((track) => track.name.startsWith('c_hand') 
                    || track.name.startsWith('hand')
                    || track.name.startsWith('middle')
                    || track.name.startsWith('c_middle')
                    || track.name.startsWith('index')
                    || track.name.startsWith('c_index')
                    || track.name.startsWith('thumb')
                    || track.name.startsWith('c_thumb')
            )
            
            if (handSpecificAnim) {
                animClip.tracks.push(...handSpecificAnim)
            }
        }
        
        if (!handStatus || handStatus === 'none' || handStatus !== 'HANDS_NATURAL') {
            const animClipNoHandTracks = animClip.tracks
                .filter((track) => !track.name.startsWith('c_hand') 
                    || !track.name.startsWith('hand')
                    || !track.name.startsWith('middle')
                    || !track.name.startsWith('c_middle')
                    || !track.name.startsWith('index')
                    || !track.name.startsWith('c_index')
                    || !track.name.startsWith('thumb')
                    || !track.name.startsWith('c_thumb')
            )
            animClip.tracks = animClipNoHandTracks
        }

        const finalClip = mixer.clipAction(animClip)
        finalClip.reset().fadeIn(0.5).play()

        if (onAnimationLoop) {
            mixer.addEventListener('loop', () => {
                handleAnimationLoop(activeAnimation)
            })
        }

        return () => {
            finalClip.reset().fadeOut(0.5)
            mixer.removeEventListener('loop', () => {
                handleAnimationLoop(activeAnimation)
            })
        }
    }, [animationName, handStatus, chosenAnims, previewHandShape])

    useEffect(() => {
        const bodyMesh = bodyMeshRef.current
        if (!bodyMesh) {
            return
        }
        bodyMesh.frustumCulled = false
        bodyMesh.castShadow = true
        bodyMesh.receiveShadow = true
        const bodyMaterial = bodyMesh.material as THREE.MeshStandardMaterial
        bodyMaterial.color = new THREE.Color(skinColor as THREE.ColorRepresentation)
    }, [skinColor])

    const combineTextures = (eyesTexture: THREE.Texture, mouthTexture: THREE.Texture) => {
        const offscreenCanvas = new OffscreenCanvas(eyesTexture.image.width, eyesTexture.image.height)
        const ctx = offscreenCanvas.getContext('2d')

        if (!ctx) {
            console.error('Unable to get 2D context.')
            return
        }
        ctx.fillStyle = 'rgba(0,0,0,0)'
        ctx.fillRect(0, 0, offscreenCanvas.width, offscreenCanvas.height)

        // TODO: this was introduced for the safari fix, may need removal
        // rotate the canvas 180 degrees
        ctx.translate(offscreenCanvas.width / 2, offscreenCanvas.height / 2)
        ctx.rotate(Math.PI)
        ctx.translate(-offscreenCanvas.width / 2, -offscreenCanvas.height / 2)
        // combine the textures
        ctx.globalCompositeOperation = 'destination-over'
        ctx.drawImage(eyesTexture.image, 0, 0)
        ctx.drawImage(mouthTexture.image, 0, 0)

        // create a texture using the offscreen canvas
        const combinedTexture = new CanvasTexture(offscreenCanvas.transferToImageBitmap())
        // this doesn't visually flip anything, but make sure the export is correct
        combinedTexture.flipY = false
        combinedTexture.needsUpdate = true
        return new MeshStandardMaterial({
            map: combinedTexture,
            transparent: true,
            alphaToCoverage: true,
            alphaTest: 0.01,
        })
    }

    useEffect(() => {
        const eyesReady = !!eyesTexture && !isEyesTextureLoading
        const mouthReady = !!mouthTexture && !isMouthTextureLoading
        onLoading({ eyes: !eyesReady, mouth: !mouthReady })

        const expressionMesh = expressionMeshRef.current
        if (!expressionMesh) {
            return
        }

        let material
        if (eyesReady && mouthReady) {
            material = combineTextures(eyesTexture, mouthTexture)
        } else {
            material = new MeshStandardMaterial({ transparent: true, opacity: 0, alphaToCoverage: true })
        }
        expressionMesh.frustumCulled = false
        const expressionMaterial = material as MeshStandardMaterial
        expressionMesh.material = expressionMaterial
        expressionMesh.material.needsUpdate = true
    }, [eyesTexture, mouthTexture, isEyesTextureLoading, isMouthTextureLoading])

    return (
        <group name='avatar' ref={groupRef} {...props}>
            <primitive object={scene} />
            {wearables.map(
                wearableProps =>
                    bodyMeshRef.current?.skeleton && (
                        <Wearable
                            key={wearableProps.slot}
                            {...wearableProps}
                            skeleton={bodyMeshRef.current.skeleton}
                            onLoading={(isLoading: boolean) => onLoading({ [wearableProps.slot]: isLoading })}
                        />
                    )
            )}
        </group>
    )
}

useGLTF.preload(MODEL_URL)

export default Avatar3Component
