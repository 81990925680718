import React, { useState, useEffect, useRef } from 'react'
import { Leva, useControls, buttonGroup, button } from 'leva'
import { createPortal } from 'react-dom'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Group } from 'three'

import AvatarCanvas from 'components/AvatarCanvas'

import { exportGlb } from 'utils/exportGlb'

import { Container, toiboxTheme } from './Styles'

import { OUTFIT_SLOTS } from 'api/Firebase/Types'
import { LOOK_ATS } from 'components/AvatarCanvas/Camera/Types'
import { AnyGlbAsset, WearableGlbAsset } from './Types'
import { ThreeRef } from 'components/AvatarCanvas/Types'

const loader = new GLTFLoader()
const DEFAULT_GLB = {
    url: '',
    filename: '',
}
const DEFAULT_WEARABLES = OUTFIT_SLOTS.map(slot => ({ slot, url: '', filename: '' }))
const NO_FILE_MESSAGE = 'no file added'
const DEFAULT_ANIMATION_OPTIONS = { 'no animations found': '' }
const DEFAULT_BACKGROUND_URL = `${process.env.PUBLIC_URL}/assets/textures/environment_background.jpg`

// open file picker
const getFile = async () =>
    new Promise<File | undefined>((resolve, reject) => {
        const input = document.createElement('input')
        input.type = 'file'
        input.onchange = () => {
            const file = input.files?.[0]
            if (!file) {
                reject()
                return
            }
            resolve(file)
        }
        input.click()
    })

const PreviewView: React.FC = () => {
    const threeRef = useRef<ThreeRef>(null)
    const previousAnimationUrl = useRef<string>('')

    const [customBody, setCustomBody] = useState<AnyGlbAsset>(DEFAULT_GLB)
    const [customWearables, setCustomWearables] = useState<WearableGlbAsset[]>(DEFAULT_WEARABLES)
    const [customAnimations, setCustomAnimations] = useState<AnyGlbAsset>(DEFAULT_GLB)
    const [animationOptions, setAnimationOptions] = useState<any>(DEFAULT_ANIMATION_OPTIONS)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const addCustomBody = async () => {
        const file = await getFile()
        if (!file) {
            alert('Unable to use file')
            return
        }
        const url = URL.createObjectURL(file)
        setCustomBody({ url, filename: file.name })
    }

    const clearCustomBody = () => {
        setCustomBody(DEFAULT_GLB)
    }

    const addCustomWearable = async (slot: WearableGlbAsset['slot']) => {
        const file = await getFile()
        if (!file) {
            alert('Unable to use file')
            return
        }
        const url = URL.createObjectURL(file)
        setCustomWearables((prev: WearableGlbAsset[]) => {
            const index = prev.findIndex(w => w.slot === slot)
            if (index === -1) {
                return prev
            }
            const newWearables = [...prev]
            newWearables[index] = { slot, url, filename: file.name }
            return newWearables
        })
    }

    const addCustomAnimations = async () => {
        const file = await getFile()
        if (!file) {
            alert('Unable to use file')
            return
        }
        const url = URL.createObjectURL(file)
        previousAnimationUrl.current = url

        const buffer = await file.arrayBuffer()
        try {
            const data = await loader.parseAsync(buffer, '')
            const animations = data.animations
            if (!animations) {
                alert('No animations found in file')
                setCustomAnimations({ url: '', filename: '' })
                return
            }
            const options = animations.reduce((obj: any, current: any) => {
                let label = current.name
                // sanitize name to only include letters and numbers
                label = label.replace(/[^a-zA-Z0-9]/g, ' ')
                label = label.replace(/\s+/g, '_')
                obj[label] = current.name
                return obj
            }, {})

            setAnimationOptions(options)
        } catch (error) {
            alert('Unable to parse file')
            setCustomAnimations({ url: '', filename: '' })
            return
        }

        setCustomAnimations({ url, filename: file.name })
    }

    const clearCustomAnimations = () => {
        setCustomAnimations(DEFAULT_GLB)
        setAnimationOptions(DEFAULT_ANIMATION_OPTIONS)
    }

    const clearCustomWearable = (slot: WearableGlbAsset['slot']) => {
        setCustomWearables((prev: WearableGlbAsset[]) => {
            const index = prev.findIndex(w => w.slot === slot)
            if (index === -1) {
                return prev
            }
            const newWearables = [...prev]
            newWearables[index] = { ...DEFAULT_GLB, slot }
            return newWearables
        })
    }

    const handleCharacterExport = async (asBinary: boolean) => {
        const { current } = threeRef
        if (!current) {
            return
        }

        const scene = current.getScene()
        const exportGroup = scene.getObjectByName('avatar')
        const blob = await exportGlb(exportGroup, asBinary)

        // init download
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = `avatar.gl${asBinary ? 'b' : 'tf'}`
        link.click()

        // clean up
        URL.revokeObjectURL(url)
        link.remove()
    }

    const [{ customEyes, customMouth, customSkinColor }, setAvatarControls] = useControls('Avatar', () => ({
        customSkinColor: { value: '#fab', label: 'skin color' },
        customEyes: { image: undefined, hint: 'a .png texture', label: 'eyes' },
        customMouth: { image: undefined, hint: 'a .png texture', label: 'mouth' },
        customBody: {
            value: NO_FILE_MESSAGE,
            label: 'body',
            editable: false,
            hint: `a .glb file containing a 'body_mesh' and 'expression_mesh'`,
        },
        'body actions': buttonGroup({
            label: '',
            opts: {
                Upload: () => addCustomBody(),
                Clear: () => clearCustomBody(),
            },
        }),
    })) as any

    const [{}, setOutfitControls] = useControls('Outfit', () => {
        return OUTFIT_SLOTS.reduce((obj: any, slot) => {
            obj[slot] = {
                value: NO_FILE_MESSAGE,
                editable: false,
                hint: 'a .glb wearable file added to the scene',
            }
            obj[`${slot} actions`] = buttonGroup({
                label: '',
                opts: {
                    Upload: () => addCustomWearable(slot),
                    Clear: () => clearCustomWearable(slot),
                },
            })
            return obj
        }, {})
    }) as any

    const { customBackground } = useControls('World', {
        customBackground: { image: undefined, label: 'background', hint: 'a .jpg sky-box file' },
    }) as any

    const [{ customAnimationName }, setAnimationControls] = useControls(
        'Animation',
        () => {
            return {
                file: {
                    value: NO_FILE_MESSAGE,
                    editable: false,
                    hint: `a .glb file containing one or more animations`,
                },
                customAnimationName: {
                    value: '',
                    label: 'animation',
                    options: animationOptions,
                    hint: `a .glb file containing one or more animations`,
                },
                'animation actions': buttonGroup({
                    label: '',
                    opts: {
                        Upload: () => addCustomAnimations(),
                        Clear: () => clearCustomAnimations(),
                    },
                }),
            }
        },
        [animationOptions]
    ) as any

    const { handStatus } = useControls(
        'Hand Shape',
        {
            handStatus: {
                value: 'none',
                options: { none: 'none', cupped: 'cupped', fist: 'fist', grasped: 'grasped' },
            }
        }        
    ) as any

    const { isFullscreen, showStats, lookAt } = useControls('Config', {
        isFullscreen: { value: true, label: 'fullscreen' },
        showStats: { value: false, label: 'stats' },
        lookAt: { value: 'orbit', options: LOOK_ATS, label: 'camera position' },
        export: buttonGroup({
            label: 'export',
            opts: {
                GLB: () => handleCharacterExport(true),
                GLTF: () => handleCharacterExport(false),
            },
        }),
    }) as any

    // update the outfit filenames when a new file is uploaded
    useEffect(() => {
        customWearables.forEach(({ slot, filename }) => {
            setOutfitControls({
                [slot]: filename || NO_FILE_MESSAGE,
            })
        })
    }, [customWearables])

    // update the body filename when a new file is uploaded
    useEffect(() => {
        setAvatarControls({
            customBody: customBody.filename || NO_FILE_MESSAGE,
        })
    }, [customBody])

    // update the animation filename when a new file is uploaded
    useEffect(() => {
        setAnimationControls({
            file: customAnimations.filename || NO_FILE_MESSAGE,
        })
    }, [customAnimations])

    // update the animations select list when a new file is uploaded
    useEffect(() => {
        setAnimationControls({
            customAnimationName: animationOptions[Object.keys(animationOptions)[0]],
        })
    }, [animationOptions])

    // hack to force re-render of canvas and reload animations
    useEffect(() => {
        setIsLoading(true)
        window.requestAnimationFrame(() => {
            setIsLoading(false)
        })
    }, [customBody, customWearables, customAnimations])

    const Canvas = (
        <Container className='avatar-theme'>
            <Leva theme={toiboxTheme} />
            {!isLoading && (
                <AvatarCanvas
                    threeRef={threeRef}
                    avatar={{
                        eyesUrl: customEyes,
                        mouthUrl: customMouth,
                        skinColor: customSkinColor,
                        wearables: customWearables,
                        bodyUrl: customBody.url,
                        animationName: customAnimationName,
                        animationUrl: customAnimations.url,
                        previewHandShape: handStatus,
                    }}
                    background={{
                        textureUrl: customBackground || DEFAULT_BACKGROUND_URL,
                    }}
                    camera={{
                        lookAt,
                        preview: true,
                    }}
                    showStats={showStats}
                    editor={true}
                    preview={true}
                />
            )}
        </Container>
    )

    return isFullscreen ? createPortal(Canvas, document.body) : Canvas
}

export default PreviewView
