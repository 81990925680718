import styled from 'styled-components'

export const Container = styled.div`
    font-family: var(--mtb-avatar-font-family-primary);
    font-size: 16px;
    background-color: var(--mtb-avatar-background-default);
    position: relative;
`

export const CanvasContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1/1.045;
`

export const LoadingContainer = styled.div`
    position: absolute;
    inset: 0 0 0 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
`
