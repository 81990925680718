import { useEffect, useRef, useState } from 'react'

import Menu from './Menu'
import Options from './Options'

import AvatarCanvas from 'components/AvatarCanvas'

import {CanvasContainer, Container, LoadingContainer} from './Styles'

import FirebaseService from 'api/Firebase'

import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'
import { AvatarActions } from 'store/Avatar'
import { WearablesActions } from 'store/Wearables'
import { ExpressionsActions } from 'store/Expressions'
import { BackgroundsActions } from 'store/Backgrounds'

import { OptionCategory, OPTION_CATEGORIES, Props } from './Types'
import { MenuAction } from './Menu/Types'
import { AppDispatch } from 'store/Types'
import { OUTFIT_SLOTS } from 'api/Firebase/Types'
import { UrlProps as WearableProps } from 'components/AvatarCanvas/Avatar/Wearable/Types'
import { ThreeRef } from 'components/AvatarCanvas/Types'

import { IDLE_ANIMATION_NAME } from 'components/AvatarCanvas/Avatar/Avatar'
import {clsx} from "clsx";
import {OrbitProgress} from "react-loading-indicators";
const FLOURISH_ANIMATION_NAME = 'REACTION' // TODO: update this to 'FLOURISH' once we have a new animation file

const AvatarEditorPage: React.FC<Props> = ({
        editor = true,
        animFilename,
        animName,
        userId,
        accessToken,
        firebaseConfig,
        className,
        backgroundColor,
        previewHandShape,
        storageBaseUrl,
        lambdaUrl,
        assetUrl,
        showStats,
        onSaveCallback, ...props
    }) => {

    const [activeOptionCategory, setActiveOptionCategory] = useState<OptionCategory>(editor ? OPTION_CATEGORIES[0] : 'snapshot')
    const [hideOverlay, setHideOverlay] = useState<boolean>(false)
    const [isTakingSnapshot, setIsTakingSnapshot] = useState<boolean>(false)
    const [wearables, setWearables] = useState<WearableProps[]>([])

    const firebaseDb = useRef(new FirebaseService(firebaseConfig)).current.db
    const classNames = clsx('mtb-avatar--editor', 'mtb-avatar--theme', { 'mb-avatar--saving': isTakingSnapshot }, className)

    const {
        backgrounds,
        expressions,
        avatar: { saved, staged },
    } = useAvatarSelector(state => state)

    
    const allWearables = useAvatarSelector(state => state.wearables)
    
    const dispatch = useAvatarDispatch<AppDispatch>()
    
    const canvasRef = useRef<ThreeRef | null>(null)

    const getStorageUrl = (path: string) => {
        return `${storageBaseUrl}/${path}`
    }
    
    const handleOverlayButtonTap = (action: MenuAction) => {
        switch (action) {
            case 'saveAvatar': void handleSaveTap()
                return
            case 'toggleView':
                setHideOverlay(!hideOverlay)
                return
            case 'shuffleOutfit':
                return
            default:
                setActiveOptionCategory(action)
                return
        }
    }

    const handleSaveTap = async () => {
        setIsTakingSnapshot(true)
        // setAnimationName(IDLE_ANIMATION_NAME)

        const _canvas = canvasRef.current
        if (!_canvas) return

        const canvas = _canvas.getCanvas()
        const scene = _canvas.getScene()

        const thumbnailPromise = new Promise<Blob | null>((resolve, reject) => {
            setTimeout(() => {
                canvas.toBlob(thumbnailBlob => {
                    if (thumbnailBlob) {
                        resolve(thumbnailBlob)
                    } else {
                        reject('thumbnailBlob creation failed')
                    }
                })
            }, 500)
        })

        try {
            const thumbnailBlob = await thumbnailPromise
            if (thumbnailBlob) {
                dispatch(AvatarActions.saveAvatarByUserIdThunk({ userId, accessToken, thumbnailBlob, firebaseDb, lambdaUrl, assetUrl }))
            }
        } catch (error) {
            console.error('Error generating blobs: ', error)
        }

        if (onSaveCallback) {
            onSaveCallback();
        }

        // setAnimationName(NO_ANIMATION_NAME) // Move to T pose by changing active animation to a clip that doesn't exist
        // handle the .glb export and upload
        setTimeout(() => {
            dispatch(AvatarActions.saveGlbByUserIdThunk({ userId, accessToken, scene, firebaseDb, lambdaUrl }))
        }, 500)
    }

    useEffect(() => {
        if (userId) {
            dispatch(AvatarActions.getAvatarByUserIdThunk({ userId, firebaseDb, accessToken }))
        }
    }, [userId, dispatch])

    useEffect(() => {
        // set default expressions if none are set
        if (!saved.eyes.expressionId && expressions.eyes.length > 0) {
            const defaultEyes = expressions.eyes[0]
            dispatch(AvatarActions.updateEyes(defaultEyes))
        }
        if (!saved.mouth.expressionId && expressions.mouth.length > 0) {
            const defaultMouth = expressions.mouth[0]
            dispatch(AvatarActions.updateMouth(defaultMouth))
        }
    }, [expressions, saved])

    useEffect(() => {
        // set default background if none is set
        if (!saved.background.backgroundId && backgrounds.length > 0) {
            const defaultBackground = backgrounds[0]
            dispatch(AvatarActions.updateBackground(defaultBackground))
        }
    }, [backgrounds, saved])

    useEffect(() => {
        const urls: WearableProps[] = []
        OUTFIT_SLOTS.forEach(slot => {
            const wearable = staged.outfit[slot]
            const availableWearables = allWearables[slot]
            if (typeof wearable !== 'string' && wearable.file && availableWearables.find(item => item.file === wearable.file)) {
                urls.push({
                    url: getStorageUrl(wearable.file),
                    slot,
                })
            }
        })
        setWearables(urls)
    }, [staged.outfit, allWearables])

    useEffect(() => {
        if (!userId) return
        dispatch(WearablesActions.getUserWearablesThunk({ userId, firebaseDb, accessToken }))
        dispatch(ExpressionsActions.getExpressionsThunk({ firebaseDb }))
        dispatch(BackgroundsActions.getBackgroundsThunk({ firebaseDb }))
    }, [userId])

    const [animationName, setAnimationName] = useState<string>(IDLE_ANIMATION_NAME)

    useEffect(() => {
        if (editor) {
            setAnimationName(FLOURISH_ANIMATION_NAME)
        }
    }, [staged.outfit])

    useEffect(() => {
        // reset camera and animation when export is complete
        setIsTakingSnapshot(false)
        if (animName && animFilename) {
            setAnimationName(animName)
        } else {
            setAnimationName(IDLE_ANIMATION_NAME)
        }
    }, [saved.characterModel])

    return (
        <Container className={classNames} {...props}>
            {isTakingSnapshot && (
              <LoadingContainer>
                <OrbitProgress variant="track-disc" speedPlus={2} easing="linear" size={"small"} color={"#FFB710"} />
              </LoadingContainer>
            )}
            <CanvasContainer>
                <AvatarCanvas
                    threeRef={canvasRef}
                    avatar={{
                        eyesUrl: staged.eyes.file ? getStorageUrl(staged.eyes.file) : '',
                        mouthUrl: staged.mouth.file ? getStorageUrl(staged.mouth.file) : '',
                        skinColor: staged.skinColor,
                        wearables,
                        animationName: animationName,
                        onAnimationLoop: () => {
                            setAnimationName(!editor ? animationName : IDLE_ANIMATION_NAME)
                        },
                        staged: staged,
                        animationFilename: animFilename,
                        previewHandShape
                    }}
                    background={{
                        textureUrl: staged.background.file && editor
                            ? getStorageUrl(staged.background.file)
                            : '',
                        editor
                    }}
                    camera={{
                        lookAt: !editor ? 'fullBody' : isTakingSnapshot ? 'snapshot' : hideOverlay ? 'skinColor' : activeOptionCategory, editor
                    }}
                    showStats={showStats}
                    editor
                    backgroundColor={backgroundColor}
                />
                {
                    editor && ( 
                        <Menu
                            onAction={handleOverlayButtonTap}
                            activeButton={activeOptionCategory}
                            isVisible={!hideOverlay && !isTakingSnapshot}
                        />
                    )
                }
            </CanvasContainer>
            {!hideOverlay && <Options optionCategory={activeOptionCategory} />}
        </Container>
    )
}

export default AvatarEditorPage
