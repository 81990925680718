import { useAvatarDispatch, useAvatarSelector } from 'store/Hooks'
import { AppDispatch, AppState } from 'store/Types'
import HorizontalScroller from '../HorizontalScroller'
import { AvatarActions } from 'store/Avatar'
import { useCallback, useMemo } from 'react'
import { OptionItem } from '../HorizontalScroller/Types'
import { BackgroundItem } from 'store/Backgrounds/Types'

const NONE_ICONS = {
    background: 'background',
}

const Backgrounds: React.FC = () => {
    const dispatch = useAvatarDispatch<AppDispatch>()
    const {
        backgrounds,
        avatar: { saved, staged },
    } = useAvatarSelector<AppState>(state => state)

    const handleBackgroundSelect = useCallback(
        (item: OptionItem) => {
            let selectedBackground = backgrounds.find(
                (background: BackgroundItem) => background.backgroundId === item.id
            )
            if (selectedBackground) {
                dispatch(AvatarActions.updateBackground(selectedBackground))
            }
        },
        [backgrounds]
    )

    const createBackgroundItems = useMemo(() => {
        const optionItems: OptionItem[] = []
        backgrounds.forEach((background: BackgroundItem) => {
            const isSaved = saved.background.backgroundId === background.backgroundId
            optionItems.push({
                id: background.backgroundId,
                name: background.name,
                thumbnail: background.thumbnail,
                status: isSaved
                    ? 'saved'
                    : staged.background.backgroundId === background.backgroundId
                    ? 'staged'
                    : 'none',
            })
        })
        return optionItems
    }, [backgrounds, saved.background, staged.background])

    const items = createBackgroundItems

    return <HorizontalScroller onSelect={handleBackgroundSelect} items={items} />
}

export default Backgrounds
