import { createSlice } from '@reduxjs/toolkit'

import getAvatarByUserIdThunk from './Actions/getAvatarByUserId'
import saveAvatarByUserIdThunk from './Actions/saveAvatarByUserId'
import saveGlbByUserIdThunk from './Actions/saveGlbByUserId'
import updateBackground from './Actions/updateBackground'
import updateEyes from './Actions/updateEyes'
import updateMouth from './Actions/updateMouth'
import updateOutfit from './Actions/updateOutfit'
import updateSkinColor from './Actions/updateSkinColor'

import { AvatarState } from './Types'

const defaultAvatar: AvatarState['staged'] = {
    eyes: {
        expressionId: '',
        file: '',
        position: {
            x: 0,
            y: 0,
        },
        scale: 1,
    },
    mouth: {
        expressionId: '',
        file: '',
        position: {
            x: 0,
            y: 0,
        },
        scale: 1,
    },
    skinColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
    background: {
        backgroundId: '',
        file: '',
    },
    outfit: {
        userOutfitId: '',
        eyewear: {
            wearableId: '',
            file: '',
        },
        feet: {
            wearableId: '',
            file: '',
        },
        hands: {
            wearableId: '',
            file: '',
        },
        head: {
            wearableId: '',
            file: '',
        },
        legs: {
            wearableId: '',
            file: '',
        },
        torsoInner: {
            wearableId: '',
            file: '',
        },
        torsoOuter: {
            wearableId: '',
            file: '',
        },
    },
    characterModel: '',
    characterModelWithHandWearable: '',
    thumbnail: '',
}

const initialState: AvatarState = {
    saved: defaultAvatar,
    staged: defaultAvatar,
    status: 'idle',
}

const avatarSlice = createSlice({
    name: 'avatar',
    initialState,
    reducers: {
        updateSkinColor,
        updateBackground,
        updateEyes,
        updateMouth,
        updateOutfit,
    },
    extraReducers(builder) {
        // getAvatarByUserIdThunk
        builder.addCase(getAvatarByUserIdThunk.pending, state => {
            return {
                ...state,
                status: 'loading',
            }
        })
        builder.addCase(getAvatarByUserIdThunk.fulfilled, (state, action) => {
            return {
                ...state,
                saved: {
                    ...state.saved,
                    ...action.payload,
                },
                staged: {
                    ...state.staged,
                    ...action.payload,
                },
                status: 'idle',
            }
        })
        builder.addCase(getAvatarByUserIdThunk.rejected, state => {
            return {
                ...state,
                status: 'failed',
            }
        })

        // saveAvatarByUserIdThunk
        builder.addCase(saveAvatarByUserIdThunk.pending, state => {
            return {
                ...state,
                status: 'loading',
            }
        })
        builder.addCase(saveAvatarByUserIdThunk.fulfilled, (state, action) => {
            return {
                ...state,
                saved: action.payload,
                staged: action.payload,
                status: 'idle',
            }
        })
        builder.addCase(saveAvatarByUserIdThunk.rejected, state => {
            return {
                ...state,
                status: 'failed',
            }
        })

        // saveGlbByUserIdThunk
        builder.addCase(saveGlbByUserIdThunk.pending, state => {
            // TODO: passing for now, UI doesn't need to know about this
            return state
        })
        builder.addCase(saveGlbByUserIdThunk.fulfilled, (state, action) => {
            // TODO: passing for now, UI doesn't need to know about this
            return {
                ...state,
                saved: {
                    ...state.saved,
                    characterModel: action.payload.characterModel,
                    characterModelWithHandWearable: action.payload.characterModelWithHandWearable,
                },
                staged: {
                    ...state.staged,
                    characterModel: action.payload.characterModel,
                    characterModelWithHandWearable: action.payload.characterModelWithHandWearable,
                },
            }
        })
        builder.addCase(saveGlbByUserIdThunk.rejected, state => {
            // TODO: passing for now, UI doesn't need to know about this
            return state
        })
    },
})

export const AvatarActions = {
    ...avatarSlice.actions,
    getAvatarByUserIdThunk,
    saveAvatarByUserIdThunk,
    saveGlbByUserIdThunk,
}
export const AvatarReducer = avatarSlice.reducer
