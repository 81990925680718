import { createSlice } from '@reduxjs/toolkit'

import getExpressionsThunk from './Actions/getExpressionsThunk'

import { ExpressionsState } from './Types'

const initialState: ExpressionsState = {
    eyes: [],
    mouth: [],
}

const expressionsSlice = createSlice({
    name: 'expressions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getExpressionsThunk.pending, state => {
            // TODO: change loading state here
            return state
        })
        builder.addCase(getExpressionsThunk.fulfilled, (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        })
        builder.addCase(getExpressionsThunk.rejected, state => {
            // TODO: pop toast here
            return state
        })
    },
})

export const ExpressionsActions = { ...expressionsSlice.actions, getExpressionsThunk }
export const ExpressionsReducer = expressionsSlice.reducer
