import styled, { css, keyframes } from 'styled-components'

import { LoadingStyle } from './Types'

const getLoading = ({ isLoading }: LoadingStyle) => {
    return css`
        opacity: ${isLoading ? 1 : 0};
    `
}

const loadingAnimation = keyframes`
0% {
	background-position-x: 0;
}
100% {
	background-position-x: 2000px;
}
`

export const LoadingBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    z-index: 1;
    background-image: var(--mtb-avatar-loading-gradient);
    background-size: 1000px;
    animation-name: ${loadingAnimation};
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 4s;
    transition: opacity 500ms;

    ${getLoading}
`
