import React, { useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { Props } from './Types'

const loader = new GLTFLoader()

const Wearable3Component: React.FC<Props> = ({ url, slot, skeleton, onLoading }: Props) => {
    const [gltf, setGltf] = useState<GLTFExtended | null>(null)
    const previousUrl = useRef<string>('')

    useEffect(() => {
        previousUrl.current = url

        if (!url || !loader) {
            return
        }

        onLoading(true)
        loader.load(
            url,
            gltf => {
                if (previousUrl.current !== url) {
                    return
                }
                gltf.scene.traverse((object: any) => {
                    if (object.isSkinnedMesh) {
                        object.castShadow = true
                        object.receiveShadow = true

                        const meshNode = object as THREE.SkinnedMesh
                        meshNode.skeleton = skeleton
                    }
                })
                onLoading(false)
                setGltf(gltf)
            },
            undefined,
            error => {
                onLoading(false)
                previousUrl.current = ''
                console.error(error)
            }
        )
    }, [url, skeleton])

    return gltf !== null ? <primitive name={slot} object={gltf?.scene} /> : null
}

export default Wearable3Component
