import styled, { css } from 'styled-components'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/grid'

import { OptionTileStyle } from './Types'

const tileSize = 5.6
const borderWidth = 0.4
const tileSlashSize = Math.sqrt(tileSize ** 2 + tileSize ** 2) - borderWidth * 2.75

export const Container = styled.div`
    .swiper-wrapper {
        min-width: 100%;
        padding: 0 0.75rem;
    }

    .swiper-slide {
        width: fit-content;
    }
`

export const TitleText = styled.h3`
    margin: 0 0 0.5rem 0.75rem;
    font: var(--mtb-avatar-font-family-primary);
    color: var(--mtb-avatar-title-primary-text);
    font-weight: 700;
    font-size: 1.2rem;
`

const getTileBorder = ({ status, isNone }: OptionTileStyle) => {
    const colorKey = status === 'none' ? 'default' : status
    const color = `var(--mtb-avatar-border-color-${colorKey})`

    return css`
        border-color: ${color};
        ${isNone &&
        css`
            &:before {
                display: block;
                position: absolute;
                content: '';
                top: ${borderWidth * -0.5}rem;
                left: 0;
                width: ${borderWidth}rem;
                height: ${tileSlashSize}rem;
                transform: translateX(${tileSize - borderWidth * 2}rem) rotate(45deg);
                transform-origin: 0 0;
                z-index: 999;
                background-color: ${color};
                border-radius: ${borderWidth * 0.5}rem;
            }
        `}
    `
}

export const OptionTile = styled.button<OptionTileStyle>`
    position: relative;
    width: ${tileSize}rem;
    height: ${tileSize}rem;
    margin: 0 0.7rem 0.7rem 0;

    border: ${borderWidth}rem solid;
    border-radius: 1rem;
    ${getTileBorder};

    background-color: var(--mtb-avatar-button-option-fill);
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: transform 100ms ease-out;

    &:active {
        transform: scale3d(0.95, 0.95, 0.95);
        transition: none;
    }

    svg {
        width: 80%;
        height: 80%;
        fill: var(--mtb-avatar-button-option-icon);
    }
`
