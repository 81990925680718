import { getDatabase } from 'firebase/database'
import { FirebaseOptions, initializeApp } from 'firebase/app'

class FirebaseService {
    db: any

    constructor(CONFIG: FirebaseOptions) {
        const app = initializeApp(CONFIG, 'mtb-avatar--firebase')
        this.db = getDatabase(app)
    }
}

export default FirebaseService
