import { OutfitSlot, OUTFIT_SLOTS } from './Types'

class Routes {
    private static base = 'avatars'
    private static userAvatarsBase = '/userAvatars'
    private static userOutfitsBase = '/userOutfits'
    private static userWearablesBase = '/userWearables'
    private static wearablesBase = '/wearables'
    private static expressionsBase = '/expressions'
    private static backgroundsBase = '/backgrounds'

    private static getPathTo(key?: string) {
        return key ? `/${key}` : ''
    }

    private static joinPath(paths: string[]) {
        // join the path together until reaching the first empty string, then quit and return the result
        let path = this.base
        for (let i = 0; i < paths.length; i++) {
            if (paths[i] === '') {
                break
            }
            path += paths[i]
        }
        return path
    }

    public static userAvatarsAll = () => {
        return this.joinPath([this.userAvatarsBase])
    }

    public static userAvatars = (userId: uid, key?: string) => {
        if (!userId) throw new Error('userId cannot be undefined, try userAvatarsAll')

        const paths = [this.userAvatarsBase, this.getPathTo(userId), this.getPathTo(key)]
        return this.joinPath(paths)
    }

    public static userOutfitsAll = () => {
        return this.joinPath([this.userOutfitsBase])
    }

    public static userOutfits = (userId: uid, outfitId?: uid, key?: string) => {
        if (!userId) throw new Error('userId cannot be undefined, try userOutfitsAll')

        const paths = [this.userOutfitsBase, this.getPathTo(userId), this.getPathTo(outfitId), this.getPathTo(key)]
        return this.joinPath(paths)
    }

    public static userWearablesAll = () => {
        return this.joinPath([this.userWearablesBase])
    }

    public static userWearables = (userId: uid, slot?: OutfitSlot, wearableId?: uid, key?: string) => {
        if (!userId) throw new Error('userId cannot be undefined, try userWearablesAll')
        // if slot is defined, make sure it is one of the possible values of OutfitSlot
        if (slot && !OUTFIT_SLOTS.includes(slot)) throw new Error(`Invalid slot: ${slot}`)

        const paths = [
            this.userWearablesBase,
            this.getPathTo(userId),
            this.getPathTo(slot),
            this.getPathTo(wearableId),
            this.getPathTo(key),
        ]
        return this.joinPath(paths)
    }

    public static wearablesAll = () => {
        return this.joinPath([this.wearablesBase])
    }

    public static wearables = (wearableId: uid, key?: string) => {
        if (!wearableId) throw new Error('wearableId cannot be undefined, try wearablesAll')

        const paths = [this.wearablesBase, this.getPathTo(wearableId), this.getPathTo(key)]
        return this.joinPath(paths)
    }

    public static expressionsAll = () => {
        return this.joinPath([this.expressionsBase])
    }

    public static expressions = (expressionId: uid, key?: string) => {
        if (!expressionId) throw new Error('expressionId cannot be undefined, try expressionsAll')

        const paths = [this.expressionsBase, this.getPathTo(expressionId), this.getPathTo(key)]
        return this.joinPath(paths)
    }
    
    public static backgroundsAll = () => {
        return this.joinPath([this.backgroundsBase])
    }
    
    public static backgrounds = (backgroundId: uid, key?: string) => {
        if (!backgroundId) throw new Error('backgroundId cannot be undefined, try backgroundsAll')
    
        const paths = [this.backgroundsBase, this.getPathTo(backgroundId), this.getPathTo(key)]
        return this.joinPath(paths)
    }
}

export default Routes
